import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import Logo from "../../assets/Coin control.gif";

const LoaderComp = () => {
    return (
        <Flex
            className="Error"
            column
            vAlign="center"
            hAlign="center"
            fill style={{ height: "100vh", width: "100vw" }}
        >
            <img src={Logo} alt="No timeline assigned"
                style={{
                    height: "auto",
                    maxHeight: "40vh",
                    width: "80%",
                    maxWidth: "600px",
                    objectFit: "contain",
                }} />
            <Text
                align="center"
                content="Loading..."
                styles={{ fontSize: "clamp(16px, 4vw, 25px)", color: "#5633D1", fontWeight: 700 }}
                weight="semibold"
            />
        </Flex>
    );
};

export default LoaderComp;