import axios from "axios";
import { getAppInsights } from "../telemetry/telemetryService";
import { TraceEvent } from "../constant";
import { MyStorage } from "../storage/myStorage";

interface QuerySchema {
  [key: string]: any;
}

export interface HttpServiceSchema {
  httpGet: (path: string, query: QuerySchema) => Promise<any>;
  httpDelete: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPost: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPut: (path: string, query: QuerySchema, payload: any) => Promise<any>;
}

const handleErrorResp = (err: any) => {
  return err.response && err.response.data
    ? Promise.reject(err.response.data)
    : Promise.reject(err);
};

/**
 * This function makes a HTTP GET request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @returns {Promise} promise containing response data or errors
 */

export const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 1000 * 60 * 5;
axiosInstance.interceptors.request.use(
  async (config: any) => {
    config.headers["Content-Type"] = "application/json";
    // config.headers["x-teams-origin"] = window.location.origin;
    // config.headers["token"] = API_SECRET_TOKEN;
    config.headers["Authorization"] = `Bearer ${MyStorage.getItem("msTeamsToken")}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// ai.initialize();
const { appInsights, trackTrace } = getAppInsights();


/**
 * This function makes a HTTP GET request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @returns {Promise} promise containing response data or errors
 */
export const httpGet = async (url: string, query = {}) => {
  try {
    const res = await axiosInstance.get(url, query);
    return res.data;
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, url });
    return handleErrorResp(err);
  }
};

export const httpGetForAD = async (url: string, query = {}) => {
  try {
    const res = await axios.get(url, query);
    return res.data;
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, url });
    return handleErrorResp(err);
  }
};


/**
 * This function makes a HTTP POST request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional body containing the request payload
 * @returns {Promise} promise containing response data or errors
 */
export const httpPost = async (url: string, payload = {}, query = {}) => {
  try {
    const res = await axiosInstance.post(url, payload, query);
    return res.data;
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, ...payload, url });

    return handleErrorResp(err);
  }
};


/**
 * This function makes a HTTP PUT request using the args provided
 * @param path the path to the microservice endpoint
 * @param query optional query containing params in keyvalue pairs
 * @param payload optional body containing the request payload
 * @returns promise containing response data or errors
 */
export const httpPut = async (url: string, payload = {}, query = {}) => {
  try {
    return await axiosInstance.put(url, payload, query);
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, ...payload });
    return handleErrorResp(err);
  }
};


/**
 * This function makes a HTTP DELETE request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional payload containing data in keyvalue pairs;
 *   although not customary, some of our endpoints for DELETE requests expect a payload.
 * @returns {Promise} containing response data or errors
 */
export const httpDelete = async (url: string, query = {}) => {
  try {
    const res = await axiosInstance.delete(url, query);
    return res.data;
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, url });
    return handleErrorResp(err);
  }
};

export const httpPatch = async (url: string, payload = {}, query = {}) => {
  try {
    return await axiosInstance.patch(url, payload, query);
  } catch (err: any) {
    // trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, url });

    return handleErrorResp(err);
  }
};




